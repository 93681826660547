.pagination-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 2rem 0;
    margin-top: .8rem;
    height: 11vh;
}
.pagination-container .pagination{
    display: flex;
    color: #fff;
    gap: .4rem;
}
.pagination-container .pagination button{
    padding: 1rem;
    color: #fff;
    background-color: var(--secodary-color);
    border-radius: .5rem;
}