.pending_order {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pending_order h1 {
  color: var(--primary-color);
  font-family: Plus Jakarta Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.pending_order .pending_btn {
  margin-top: 10rem;
  text-align: center;
  border-radius: 10px;
  background: var(--primary-color);
  color: #333;
  font-family: Plus Jakarta Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 16px 70px;
}
