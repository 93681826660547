.pending_orders{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pending_orders p{
    font-size: 2rem;
    font-weight: 500;
    line-height: 4rem;
}
.pending_orders h1{
    font-size: 14rem;
    font-weight: 700;
    color: var(--bright-red);
}