.body_header {
    display         : flex;
    flex-wrap       : wrap;
    justify-content : space-between;
    align-items     : center;
    background-color: var(--gray-8);
    border-radius   : 1rem;
    padding: 2.2rem 4.4rem;
    /* margin: 4.8rem 0 2.4rem; */
    margin-bottom: 2.4rem;
}

.body_header>*:nth-child(2) {
    display: flex;
    align-items: center;
}
.body_header>*:nth-child(2)>.search_wrapper>input {
    margin-bottom: 0;
}

.order_date{
    display: flex;
    justify-content: center;
    align-items: center; 
    gap: 1rem;
}
.order_date h1{
    color: var(--gray-3);
    width: 60%;
}