.order_nav_card {
  position: relative;
}
.order_nmbrs {
  position: absolute;
  top: -22%;
  right: -50%;
  background-color: #f24235;
  width: 40px;
  height: 40px;
  /* padding: .5rem .9rem; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.order_nmbrs p {
  color: #fff;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
