.order_items_wrapper {
  padding-bottom: 6rem;
}

.order_items_wrapper .item_header {
  padding-bottom: 2rem;
}

.order_items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.order_items > * {
  /* flex: 0 0 20%; */
  /* width: 20%; */
}

.order_items > *:nth-child(1) {
  flex: 0 0 40%;
  width: 40%;
}

.order_items .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.order_items .item:first-child {
  align-items: flex-start;
}

.order_items .item:last-child {
  align-items: flex-end;
}

.order_items p {
  padding: 1rem 0 2rem 0;
}

.order_items:not(.item_header) > *:nth-child(1) p {
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.order_items:not(.item_header) > *:nth-child(1) p::-webkit-scrollbar {
  display: none;
}

.calculation {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2.4rem;
}

.calculation.grand-total {
  border-top: 1px solid #b3b3b3;
  padding-top: 2.4rem;
}

.calculation h6 {
  font-weight: 300;
  font-size: 2rem;
  color: var(--gray-3);
  font-family: "Roboto", sans-serif;
}

.calculation.grand-total h6 {
  font-weight: bold;
}

/* .calculation:nth-child(odd) {
    border-bottom: 1px solid #B3B3B3;
} */
